import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import Layout from "../components/SubLayout"
import { navItemsSr } from "../constants/navigation"

import {
  Card,
  CardsWrapper,
  H1,
  Icon,
  P,
  TextWrapper,
  WrapperHero,
} from "../styled/about"

const StyledImage = styled(GatsbyImage)`
  max-width: 420px;
  max-height: 420px;
  width: 100%;
  height: 100%;
`
class Onama extends React.Component {
  render() {
    const image = getImage(
      this.props.data.about.childImageSharp.gatsbyImageData
    )
    return (
      <Layout
        data={this.props.data}
        location={this.props.location}
        navItems={navItemsSr}
      >
        <Helmet
          title="O nama - Miia"
          meta={[
            { name: "description", content: "Sampleee" },
            { name: "keywords", content: "sampleee, something" },
          ]}
        />
        <WrapperHero>
          <TextWrapper>
            <H1>O nama</H1>
            <P>
              Zbog velike porodične ljubavi koju gajimo prema kućnim ljubimcima,
              2000. godine odlučili smo da osnujemo kompaniju koja proizvodi i
              prodaje izuzetno moderne i kvalitetne madrace i ležaljke. Vodeći
              računa o uslovima kvaliteta naših proizvoda, kroz porodičnu
              automatizovanu manofakturu, održavamo dobru organizaciju i
              poštujemo rokove isporuke kako na domaćem tako i na stranom
              tržištu. Posedujemo izuzetno modernu tehnologiju i opremu kako
              bismo u potpunosti ispunili očekivanja naših klijenata. U našem
              timu rade vrlo posvećeni i obučeni modelari, dizajneri, krojači i
              šivare.
            </P>
          </TextWrapper>
          <StyledImage image={image} alt="" />
        </WrapperHero>
        <CardsWrapper>
          <Card>
            <Icon fixed={this.props.data.dog.sharp.fixed} />
            <h4>Briga za male članove porodice</h4>
            <p>
              Svaki ljubimac ima jedinstven karakter i potrebe. Upravo iz tih
              razloga, pri proizvodnji naših proizvoda, biramo isključivo
              najkvalitetnije materijale i precizne oblike koji su anatomski
              prilagođene prirodnim potrebama kućnih ljubimaca.
            </p>
          </Card>
          <Card>
            <Icon fixed={this.props.data.maintenance.sharp.fixed} />
            <h4>Dizajn i održavanje</h4>
            <p>
              Dizajn je retro teksture materijala. Proizvodi su napravljeni od
              prirodno kvalitetnih štofova, podloga je neklizajuća, dok se
              unutrašnjost proizvoda ispunjava kvalitetnom memori penom ili
              koflinom. štofovi su nepromočivi materijali koji su veoma prijatni
              na dodir. Održavanje i čišćenje proizvoda je veoma jednostavno, na
              30 ºC.
            </p>
          </Card>
          <Card>
            <Icon fixed={this.props.data.productIcon.sharp.fixed} />
            <h4>Širok asortiman proizvoda</h4>
            <p>
              Miia brend nudi širok asortiman proizvoda - ležaljke, ležaljke sa
              grejačima, prostirke, sofe i auto-transporteri. Proizvodi se
              izrađuju u M, L, XL i XXL veličinama.
            </p>
          </Card>
        </CardsWrapper>
        <div style={{ height: "48px" }}></div>
      </Layout>
    )
  }
}

export default Onama

export const onamaQuery = graphql`
  query OnamaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    dog: file(relativePath: { eq: "dog.png" }) {
      sharp: childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mainlogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
    maintenance: file(relativePath: { eq: "maintenance.png" }) {
      sharp: childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    productIcon: file(relativePath: { eq: "productIcon.png" }) {
      sharp: childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    about: file(relativePath: { eq: "about-transparent.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
  }
`
