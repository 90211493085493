import GatsbyImage from "gatsby-image"
import styled from "styled-components"

export const Height = styled.div`
  height: 100%;
  min-height: calc(100vh - 320px);
`

export const Img = styled(GatsbyImage)`
  position: absolute !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transform: scale(0.8);
  max-width: 420px;
`

export const Icon = styled(GatsbyImage)`
  margin-top: 54px;
  margin-bottom: 32px;
  width: 52px;
  height: 52px;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
`

export const Card = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 428px;
  background: #ffffff;
  box-shadow: 2px 2px 25px rgba(84, 84, 84, 0.08);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 23px;

  @media (max-width: 1130px) {
    width: 100%;
    max-width: 600px;
  }

  h4 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #222222;
    margin-bottom: 24px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7a7a7a;
  }
`

export const WrapperHero = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  margin: 0;
  @media (max-width: 1130px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0;
    height: auto;
  }
`

export const Blob = styled.div`
  background-image: ${props => `url(${props.url})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  order: 1;
  @media (max-width: 1130px) {
    order: -1;
  }
`

export const H1 = styled.h1`
  font-weight: 400;
  font-size: 39px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: #222222;
  margin-bottom: 48px;
  @media (max-width: 460px) {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
  }
`

export const P = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
`

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 627px;
  @media (max-width: 1130px) {
    margin-bottom: 20px;
  }
`
